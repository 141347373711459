<template>
	<v-dialog class="v-dialog-crop" title="剪裁图片" closable>
		xxx
		<v-button @click="resolve()">确定</v-button>
	</v-dialog>
</template>

<script>
export default {
  data() {
    return {
      resolve: async () => {}
    };
  },
  methods: {
    xxx() {}
  },
  created(files, resolve) {
    this.resolve = resolve;
    console.log(
      files.map(file => {
        return {
          file
        };
      })
    );
  }
};
</script>

<style lang="less">
.v-dialog-crop {
}
</style>